import { http } from "./service.js";
const api = {
  getChargeList(data) {
    //获取缴费单列表
    return http.post("/api/studentCharge/paymentList", data);
  },
  getPaymentDetail(data) {
    //获取缴费单详情
    return http.post("/api/studentCharge/paymentDetail", data);
  },
  createAbcOrder(data) {
    //生成农行订单去缴费
    return http.post("/api/studentCharge/payment", data);
  },
  createUnionPayOrder(data) {
    //生成银联订单去缴费
    return http.post("/api/H5Pay/chinaumsPay", data);
  },
  createCEBPayOrder(data) {
		//生成非税订单去缴费
		return http.post("/api/studentCharge/payment", data);
	},
  createBocScheme(data){
    //中行缴费scheme(中行欠缴模式)
		return http.post("/api/BOCOwing/schoolBank", data);
  },
  createTeacherBocScheme(data){
		return http.post("/api/BOCOwing/schoolBank", data);
  },
  getChildren() {
    //获取用户的孩子
    return http.get("/api/child/get");
  },

  login(data) {
    //用户登录
    return http.post("/api/login/login", data);
  },
  loginWithOpenId(data){
    return http.post("/api/login/useOpenIdPWLogin",data);
  },
  userUnbind(data) {
    //用户解除绑定
    return http.post("/api/login/unbindingOpenId",data);
    // return http.post("/api/user/Unbind", data);
  },
  getUserList(data) {
    //获取已绑定的用户列表
    return http.post("/api/user/getBindUserList", data);
  },
  //添加昵称，姓名，身份证
  addIdentity(data){
    return http.post("/api/user/addIdentity",data);
  },
  updateStudentName(data){
    return http.post("/api/student/updateStudentName",data);
  },

  getTeacherPaymentList(data) {
    //获取缴费单列表
    return http.post("/api/teacherCharge/paymentList", data);
  },
  getTeacherPaymentDetail(data) {
    //获取缴费单详情
    return http.post("/api/teacherCharge/paymentDetail", data);
  },
  createTeacherAbcOrder(data) {
    //生成农行订单去缴费
    return http.post("/api/teacherCharge/payment", data);
  },
  createTeacherCEBPayOrder(data) {
		//生成非税订单去缴费
		return http.post("/api/teacherCharge/payment", data);
	},
  teacherChargeList(data){
    //老师查询所在班级的大缴费单列表
    return http.post("/api/teacherCharge/chargeList",data);
  },
  teacherChargeInfo(data){
    //老师根据t_charge_teacher_id查询所在班级成员的缴费列表
    return http.post("/api/teacherCharge/chargeInfo",data);
  },

  classList(data){
    //老师负责的班级列表
    return http.post("/api/teacher/classList",data);
  },
  studentList(data){
    //老师负责班级的学生列表
    return http.post("/api/teacher/studentList",data);
  },

  //修改密码
  resetPwd(data) {
    return http.post("/api/login/changePassword", data);
  },
};
export default api;
