import { createRouter, createWebHashHistory } from "vue-router";
import {setStorage, getStorage } from "../common/js/storeUtil";
import Payment from '../views/Payment.vue'
const routes = [
  // {
  //   path: "/",
  //   component: () => import("../views/UserList.vue"),
  // },
  {
    path: "/",
    component: () => import("../views/UserTypeChoose.vue"),
  },
  {
    path: "/teacherHome",
    name:"teacherHome",
    component: () => import("../views/TeacherHome.vue"),

  },
  {
    path: "/teacherDetail",
    name:"teacherDetail",
    component: () => import("../views/TeacherPaymentDetail.vue"),

  },
  {
    path: "/classes",
    name:"classes",
    component: () => import("../views/teacher/Classes.vue"),

  },
  {
    path: "/students",
    name:"students",
    component: () => import("../views/teacher/Students.vue"),

  },
  {
    path: "/classesManage",
    name:"classesManage",
    component: () => import("../views/teacher/ClassesManage.vue"),

  },
  {
    path: "/charges",
    name:"charges",
    component: () => import("../views/teacher/Charges.vue"),

  },
  {
    path: "/chargeDetail",
    name:"chargeDetail",
    component: () => import("../views/teacher/ChargeDetail.vue"),

  },
  {
    path: "/payment",
    name: "payment",
    component: () => import("../views/Payment.vue"),
  },
  {
    path: "/detail",
    name: "detail",
    component: () => import("../views/PaymentDetail.vue"),
  },
  {
    path: "/child",
    name: "child",
    component: () => import("../views/Child.vue"),
  },
  {
    path: "/user",
    name: "user",
    component: () => import("../views/User.vue"),
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/About.vue"),
  },
  {
    path: "/userinfo",
    name: "userinfo",
    component: () => import("../views/UserInfo.vue"),
  },
  {
    path: "/resetpwd",
    name: "restPwd",
    component: () => import("../views/ResetPwd.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});


// router.beforeEach((to, from, next) => {
//   if (to.fullPath === "/") {
//     if (getQuery("")) {
//       setStorage("", getQuery(""))
//       next();
//     } else {
//      let openID = getStorage("");
//       if (!openID) {
//         window.location.href =
//       }else{
//         next();
//       }
//     }
//   } else {
//     next();
//   }
// });

/**
 * 获取url的query中的参数
 */
function getQuery(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return false;
}

export default router;
