<template>
  <router-view />
  <van-tabbar v-show="isShow" v-model="active">
    <van-tabbar-item icon="home-o" :to="toPath">首页</van-tabbar-item>
    <van-tabbar-item icon="user-o" to="/user">我的</van-tabbar-item>
  </van-tabbar>
</template>

<script>
import { ref, watch, onMounted } from "vue";
import { useRouter } from "vue-router";
import { getStorage } from "./common/js/storeUtil";
export default {
  setup() {
    const active = ref(0);
    const isShow = ref(false);
    const router = useRouter();
    const toPath = ref("/");

    onMounted(() => {
      active.value = 0;
    });

    watch(() => {
      if (getStorage("uType")) {
        if (getStorage("uType") == 1) {
          toPath.value = "/payment";
        } else {
          toPath.value = "/teacherHome";
        }
      }

      let path = router.currentRoute.value.fullPath;

      if (path == "/payment" || path == "/teacherHome") {
        active.value = 0;
      }

      if (path.includes("/user")) {
        active.value = 1;
      }

      if (path == "/payment" || path == "/teacherHome" || path == "/user") {
        isShow.value = true;
      } else {
        isShow.value = false;
      }
    });

    return {
      active,
      isShow,
      toPath,
    };
  },
};
// window.addEventListener("popstate", function () {
//   Toast("要退出应用吗?");
// });
</script>

<style lang="less">
body {
  background-color: #f2f2f2;
}

.footer {
  background-color: white;
  text-align: center;
  width: 100%;
  position: fixed;
  bottom: 0;
  font-size: 12px;
  color: gray;
}
</style>
