import CryptoJS from 'crypto-js';
// 十六位密钥
const SECRET_KEY = CryptoJS.enc.Utf8.parse("kunyun9999888866");
// 十六位密钥偏移量
const SECRET_IV = CryptoJS.enc.Utf8.parse("kunyun1234567890");

//密钥
const key = CryptoJS.enc.Utf8.parse("kunyun8523697410");
//偏移量
const iv = CryptoJS.enc.Utf8.parse("7410235896kunyun");

/**
 * 第一个字符用*号替换
 * @param {*} str 
 */
export function safeName(str) {
    if (str) {
        return str.replace(str.substring(0, 1), "*");
    } else {
        return "";
    }
}

/**
 * 加密方法
 * @param data
 * @returns {string}
 */
export function encryption(data) {
    if (typeof data === "object") {
        try {
            data = JSON.stringify(data);
        } catch (error) {
            // console.log("encrypt error:", error);
        }
    }
    const dataHex = CryptoJS.enc.Utf8.parse(data);
    const encrypted = CryptoJS.AES.encrypt(dataHex, SECRET_KEY, {
        iv: SECRET_IV,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.ciphertext.toString();
}

/**
 * 解密方法
 * @param data
 * @returns {string}
 */
export function decryption(data) {
    const encryptedHexStr = CryptoJS.enc.Hex.parse(data);
    const str = CryptoJS.enc.Base64.stringify(encryptedHexStr);
    const decrypt = CryptoJS.AES.decrypt(str, SECRET_KEY, {
        iv: SECRET_IV,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    const decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
    return decryptedStr.toString();
}

export const md5 = str => CryptoJS.MD5(str).toString().toLowerCase()


export const AESencrypt = (plaintext) => {
    // key = CryptoJS.enc.Utf8.parse(key)
    // iv = CryptoJS.enc.Utf8.parse(iv)
    const encryptedData = CryptoJS.AES.encrypt(plaintext, key, {
      iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    })
    return encryptedData.toString()
  }
  
  /**
   * AES 解密
   * @param ciphertext 密文
   * @param key 加密 key
   * @param iv 偏移量
   * @returns 明文
   */
  export const AESdecrypt = (ciphertext, key, iv) => {
    key = CryptoJS.enc.Utf8.parse(key)
    iv = CryptoJS.enc.Utf8.parse(iv)
    const decryptedData = CryptoJS.AES.decrypt(ciphertext, key, {
      iv,
      padding: CryptoJS.pad.Pkcs7
    })
    return decryptedData.toString(CryptoJS.enc.Utf8)
  }
