/* 加密算法
 *  采用aes-128-ecp算法
 *  key必须为16字节utf8字符（如英文字母、数字等等）
 *  iv设置为空‘’
 *  输出为hex打斜字符串（大小写不影响解密）
 */
let key = "tHisisKEY3333158";
let crypto = require("crypto");
export function encryption(data) {
  var iv = "";
  var cipherChunks = [];
  var cipher = crypto.createCipheriv("aes-128-ecb", key, iv);
  cipher.setAutoPadding(true);
  cipherChunks.push(cipher.update(data, "utf8", "hex"));
  cipherChunks.push(cipher.final("hex"));
  return cipherChunks.join("").toUpperCase();
}

//解密算法
export function decryption(data) {
  var iv = "";
  var cipherChunks = [];
  var decipher = crypto.createDecipheriv("aes-128-ecb", key, iv);
  decipher.setAutoPadding(true);
  cipherChunks.push(decipher.update(data, "hex", "utf8"));
  cipherChunks.push(decipher.final("utf8"));
  return cipherChunks.join("").toUpperCase();
}

/**
 * 第一个字符用*号替换
 * @param {*} str
 */
export function safeName(str) {
  if (str) {
    return str.replace(str.substring(0, 1), "*");
  } else {
    return "";
  }
}
/**
 *  保存cookie
 * @param {String} name 需要存储cookie的key
 * @param {String} value 需要存储cookie的value
 * @param {Number} timer 默认存储多少天
 */
export function setCookie(name, value, timer = 100) {
  var Days = timer; //默认将被保存 1 天
  var exp = new Date();
  exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000);
  document.cookie =
    name + "=" + escape(value) + ";expires=" + exp.toGMTString();
}

/**
 *  获取cookie
 * @param {String} name 需要获取cookie的key
 */
export function getCookie(name) {
  var arr = document.cookie.match(new RegExp("(^| )" + name + "=([^;]*)(;|$)"));
  if (arr != null) {
    return unescape(arr[2]);
  } else {
    return null;
  }
}

/**
 * 删除cookie
 * @param {String} name 需要删除cookie的key
 */
export function clearCookie(name) {
  var exp = new Date();
  exp.setTime(exp.getTime() - 1);
  var cval = getCookie(name);
  if (cval != null)
    document.cookie = name + "=" + cval + ";expires=" + exp.toGMTString();
}

/**
 * 清除所有cookie
 * @param {String} name 需要删除cookie的key
 */
export function clearAllCookie() {
  let date = new Date();
  date.setTime(date.getTime() - 10000);
  let keys = document.cookie.match(/[^ =;]+(?==)/g);
  if (keys) {
    for (let i = keys.length; i--; ) {
      document.cookie =
        keys[i] + "=0; expire=" + date.toGMTString() + "; path=/";
    }
  }
}

/**
 * 判断是否是android环境
 * @returns
 */
export function isAndroid() {
  var u = navigator.userAgent;
  var android =
    u.indexOf("Android") > -1 ||
    u.indexOf("Adr") > -1 ||
    u.indexOf("Linux") > -1;
  return android;
}


// 身份证相关-------------------------------------------
/**
 * 详细身份证号码校验
 * @returns 
 */
 export function checkIdNum(idNum) {
  if (idNum == " ") {
    return false;
  }
  //校验长度，类型 
  else if (isCardNo(idNum) == false) {
    return false;
  }
  //检查省份 
  else if (checkProvince(idNum) == false) {
    return false;
  }
  //校验生日 
  else if (checkBirthday(idNum) == false) {
    return false;
  }
  //检验位的检测 
  else if (checkParity(idNum) == false) {
    return false;
  } else {
    // getBirthday();
    // getSex();
    return true;
  }
}

var vcity = {
  11: "北京", 12: "天津", 13: "河北", 14: "山西", 15: "内蒙古",
  21: "辽宁", 22: "吉林", 23: "黑龙江", 31: "上海", 32: "江苏",
  33: "浙江", 34: "安徽", 35: "福建", 36: "江西", 37: "山东", 41: "河南",
  42: "湖北", 43: "湖南", 44: "广东", 45: "广西", 46: "海南", 50: "重庆",
  51: "四川", 52: "贵州", 53: "云南", 54: "西藏", 61: "陕西", 62: "甘肃",
  63: "青海", 64: "宁夏", 65: "新疆", 71: "台湾", 81: "香港", 82: "澳门", 91: "国外"
};

//检查号码是否符合规范，包括长度，类型 
function isCardNo(card) {
  //身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，最后一位是校验位，可能为数字或字符X 
  var reg = /(^\d{15}$)|(^\d{17}(\d|X)$)/;
  if (reg.test(card) == false) {
    //alert("demo"); 
    return false;
  }
  return true;
}
//取身份证前两位,校验省份 
function checkProvince(card) {
  var province = card.substr(0, 2);
  if (vcity[province] == undefined) {
    return false;
  }
  return true;
}

//检查生日是否正确 
function checkBirthday(card) {
  //debugger
  var len = card.length;
  //身份证15位时，次序为省（3位）市（3位）年（2位）月（2位）日（2位）校验位（3位），皆为数字 
  if (len == '15') {
    var re_fifteen = /^(\d{6})(\d{2})(\d{2})(\d{2})(\d{3})$/;
    var arr_data = card.match(re_fifteen);
    var year = arr_data[2];
    var month = arr_data[3];
    var day = arr_data[4];
    var birthday = new Date('19' + year + '/' + month + '/' + day);
    return verifyBirthday('19' + year, month, day, birthday);
  }
  //身份证18位时，次序为省（3位）市（3位）年（4位）月（2位）日（2位）校验位（4位），校验位末尾可能为X 
  if (len == '18') {
    var re_eighteen1 = /^(\d{6})(\d{4})(\d{2})(\d{2})(\d{3})([0-9]|X)$/;
    var arr_data1 = card.match(re_eighteen1);
    var year1 = arr_data1[2];
    var month1 = arr_data1[3];
    var day1 = arr_data1[4];
    var birthday1 = new Date(year1 + '/' + month1 + '/' + day1);
    return verifyBirthday(year1, month1, day1, birthday1);
  }
  return false;
}

//校验日期 
function verifyBirthday(year, month, day, birthday) {
  var now = new Date();
  var now_year = now.getFullYear();
  //年月日是否合理 
  if (birthday.getFullYear() == year && (birthday.getMonth() + 1) == month && birthday.getDate() == day) {
    //判断年份的范围（3岁到100岁之间) 
    var time = now_year - year;
    if (time >= 3 && time <= 100) {
      return true;
    }
    return false;
  }
  return false;
}

//校验位的检测 
function checkParity(card) {
  //15位转18位 
  //debugger
  card = changeFivteenToEighteen(card);
  var len = card.length;
  if (len == '18') {
    var arrInt = new Array(7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2);
    var arrCh = new Array('1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2');
    var cardTemp = 0, i, valnum;
    for (i = 0; i < 17; i++) {
      cardTemp += card.substr(i, 1) * arrInt[i];
    }
    valnum = arrCh[cardTemp % 11];
    if (valnum == card.substr(17, 1)) {
      return true;
    }
    return false;
  }
  return false;
}

//15位转18位身份证号 
function changeFivteenToEighteen(card) {
  if (card.length == '15') {
    var arrInt = new Array(7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2);
    var arrCh = new Array('1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2');
    var cardTemp = 0, i;
    card = card.substr(0, 6) + '19' + card.substr(6, card.length - 6);
    for (i = 0; i < 17; i++) {
      cardTemp += card.substr(i, 1) * arrInt[i];
    }
    card += arrCh[cardTemp % 11];
    return card;
  }
  return card;
}

//通过身份证获得出生日期
export function getBirthday(idNum) {
  var IDCardValue = idNum;
  var len = IDCardValue.length;
  if (len == '15') {
    var re_fifteen = /^(\d{6})(\d{2})(\d{2})(\d{2})(\d{3})$/;
    var arr_data = IDCardValue.match(re_fifteen);
    var year = arr_data[2];
    var month = arr_data[3];
    var day = arr_data[4];
    var birthday = new Date('19' + year + '/' + month + '/' + day);
    return birthday;
  }
  if (len == '18') {
    var re_eighteen1 = /^(\d{6})(\d{4})(\d{2})(\d{2})(\d{3})([0-9]|X)$/;
    var arr_data1 = IDCardValue.match(re_eighteen1);
    var year1 = arr_data1[2];
    var month1 = arr_data1[3];
    var day1 = arr_data1[4];
    var birthday1 = new Date(year1 + '/' + month1 + '/' + day1);
    return birthday1;
  }

}
//通过身份证号获取性别信息
export function getSex(idNum) {
  var IDCardValue = idNum;
  var len = IDCardValue.length;
  var seqNo = "0";
  let sex = "";
  if (len == '15') {
    seqNo = IDCardValue.substr(12, 3);
  }
  if (len == '18') {
    seqNo = IDCardValue.substr(14, 3);
  }
  if (seqNo % 2 == 0) {
     sex = "女";
  }
  if (seqNo % 2 == 1) {
     sex = "男";
  }
  return sex;
}