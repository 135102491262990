/***
 * title: storeUtil.js
 * Desc: 对存储的简单封装
 */
 
 import CryptoJS from 'crypto-js';

 // 十六位十六进制数作为密钥
 const SECRET_KEY = CryptoJS.enc.Utf8.parse("kunyun9999888866");
 // 十六位十六进制数作为密钥偏移量
 const SECRET_IV = CryptoJS.enc.Utf8.parse("kunyun1234567890");
 
 // 类型 window.localStorage,window.sessionStorage,
 const config = {
     type: 'localStorage', // 本地存储类型 sessionStorage
     prefix: 'kyabc_1_0_1', // 名称前缀 建议：项目名 + 项目版本
     isEncrypt: false // 默认加密 为了调试方便, 开发过程中可以不加密
 }
 
 // 判断是否支持 Storage
 export const isSupportStorage = () => {
     return (typeof (Storage) !== "undefined") ? true : false
 }
 
 // 设置 setStorage
 export const setStorage = (key, value) => {
    if (value === '' || value === null || value === undefined) {
        value = null;
    }
    let data = {
        value: value, // 存储值
    }
    
    const encryptString = config.isEncrypt 
    ? encrypt(JSON.stringify(data))
    : JSON.stringify(data);
    window[config.type].setItem(encrypt(key), encryptString);
 }


 // 获取 getStorage
 export const getStorage = (key) => {
     key = encrypt(key);
     // key 不存在判断
     if (!window[config.type].getItem(key) || JSON.stringify(window[config.type].getItem(key)) === 'null') {
         return null;
     }
     const storage = config.isEncrypt 
     ? JSON.parse(decrypt(window[config.type].getItem(key))) 
     : JSON.parse(window[config.type].getItem(key));
     return storage.value
 }
 
 // 是否存在 hasStorage
 export const hasStorage = (key) => {
     key = encrypt(key);
     let arr = getStorageAll().filter((item)=>{
         return item.key === key;
     })
     return arr.length ? true : false;
 }
 
 // 获取所有key
//  export const getStorageKeys = () => {
//      let items = getStorageAll()
//      let keys = []
//      for (let index = 0; index < items.length; index++) {
//          keys.push(items[index].key)
//      }
//      return keys
//  }
 
//  // 根据索引获取key
//  export const getStorageForIndex = (index) => {
//      return window[config.type].key(index)
//  }
 
//  // 获取localStorage长度
//  export const getStorageLength = () => {
//      return window[config.type].length
//  }
 
 // 获取全部 getAllStorage
 export const getStorageAll = () => {
     let len = window[config.type].length // 获取长度
     let arr = new Array() // 定义数据集
     for (let i = 0; i < len; i++) {
         // 获取key 索引从0开始
         let getKey = window[config.type].key(i)
         // 获取key对应的值
         let getVal = window[config.type].getItem(getKey)
         // 放进数组
         arr[i] = {'key': getKey, 'val': getVal,}
     }
     return arr
 }
 
 // 删除 removeStorage
 export const removeStorage = (key) => {
     window[config.type].removeItem(encrypt(key));
 }
 
 // 清空 clearStorage
 export const clearStorage = () => {
     window[config.type].clear();
 }
 
 // 名称前自动添加前缀
 const autoAddPrefix = (key) => {
     const prefix = config.prefix ? config.prefix + '_' : '';
     return  prefix + key;
 }
 
 // 移除已添加的前缀
 const autoRemovePrefix = (key) => {
     const len = config.prefix ? config.prefix.length+1 : '';
     return key.substr(len)
     // const prefix = config.prefix ? config.prefix + '_' : '';
     // return  prefix + key;
 }
 
 /**
  * 加密方法
  * @param data
  * @returns {string}
  */
 const encrypt = (data) => {
     if (typeof data === "object") {
         try {
             data = JSON.stringify(data);
         } catch (error) {
            //  console.log("encrypt error:", error);
         }
     }
     const dataHex = CryptoJS.enc.Utf8.parse(data);
     const encrypted = CryptoJS.AES.encrypt(dataHex, SECRET_KEY, {
         iv: SECRET_IV,
         mode: CryptoJS.mode.CBC,
         padding: CryptoJS.pad.Pkcs7
     });
     return encrypted.ciphertext.toString();
 }
 
 /**
  * 解密方法
  * @param data
  * @returns {string}
  */
 const decrypt = (data) => {
     const encryptedHexStr = CryptoJS.enc.Hex.parse(data);
     const str = CryptoJS.enc.Base64.stringify(encryptedHexStr);
     const decrypt = CryptoJS.AES.decrypt(str, SECRET_KEY, {
         iv: SECRET_IV,
         mode: CryptoJS.mode.CBC,
         padding: CryptoJS.pad.Pkcs7
     });
     const decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
     return decryptedStr.toString();
 }
 